/* Extra small devices (phones, 600px and down) */

.login-wrapper {
  background-color: #ffffff; /* Used if the image is unavailable */
  height: 100vh; /* You must set a specified height */
  color: #07294c;
}

.login-wrapper input,
.login-wrapper label {
  display: block;
}

.login-wrapper label:first-child {
  margin-top: 0px;
}

.login-wrapper label {
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
}

.login-wrapper input {
  margin-top: 5px;
}

.login-wrapper .img-hotel-logo {
  width: 132px;
}

.login-wrapper .img-host-logo {
  width: 90px;
}

.login-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  height: 100vh;
  padding: 60px 40px;
}

.login-form {
  width: 100%;
}

.login-form select {
  border: 1px solid #c5d5fd;
  border-radius: 5px;
  width: 100%;
  padding: 12px;
  color: #07294c;
  margin-top: 5px;
  cursor: pointer;
}

.login-form option:hover {
  cursor: pointer;
}

.login-form input {
  border: 1px solid #c5d5fd;
  border-radius: 5px;
  width: 100%;
  padding: 12px;
}

.login-form select:focus,
.login-form input:focus {
  outline: none;
}

.login-form ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c5d5fd;
  opacity: 1; /* Firefox */
}

.login-form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c5d5fd;
}

.login-form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c5d5fd;
}

.input-submit {
  margin-top: 20px !important;
  background: transparent linear-gradient(262deg, #77dbff 0%, #5c89ff 100%);
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.input-submit-block {
  margin-top: 20px !important;
  background: transparent linear-gradient(262deg, #474b4d9d 0%, #383a3db2 100%);
  color: white;
  font-size: 18px;
  cursor: not-allowed;
  opacity: 0.3;
}

.input-submit.input-submit-ready {
  background: transparent linear-gradient(262deg, #77dbff 0%, #5c89ff 100%);
}

.input-submit.input-submit-disabled {
  background: transparent linear-gradient(262deg, #474b4d9d 0%, #383a3db2 100%);
}

.loading-gif {
  width: 100px;
}

.login-form-fields-error {
  font-size: 12px;
  color: #ef9f94;
}

.select-box {
  position: relative;
  width: 100%;
}

/*To remove button from IE11, thank you Matt */
select::-ms-expand {
  display: none;
}

.select-box:after {
  content: ">";
  color: white;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 17px;
  /*Adjust for position however you want*/

  top: 18px;
  padding: 2px 8px 1px 8px;
  border-radius: 30px;
  background-color: #07294c;
  font-size: 14px;
  text-align: center;
  /*left line */

  position: absolute;
  pointer-events: none;
}

.select-box select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */

  display: block;
  width: 100%;
  max-width: 100%;
  height: 50px;
  float: right;
  margin: 5px 0px;
  padding: 0px 12px;
  font-size: 16px;
  line-height: 1.75;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #c5d5fd;
  -ms-word-break: normal;
  word-break: normal;
  margin-bottom: 15px;
}

.error-message-info{
  margin-top: 10px;
  font-size: 10px;
  border: 1px solid #ef9f94;
  background-color: #ef9f944f;
  border-radius: 5px;
  padding: 5px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .login-layout {
    padding: 80px 120px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .login-layout {
    padding: 80px 200px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .login-wrapper {
    /* background-image: url("./images/login_background.jpg");  *//* The image used */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }

  .login-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    right: 0;
    width: 370px;
    background-color: white;
    height: 100vh;
    padding: 80px 40px;
  }

  .login-wrapper .img-host-logo {
    width: 166px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .login-layout {
    width: 440px;
    padding: 80px 40px;
  }
}
